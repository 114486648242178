import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import outlook_local_tasks from '../../../../../../../common/src/assets/image/outlook_local_tasks.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing tasks defined in a locally installed version of Microsoft
        Outlook (2007 or later). If your tasks are synced with Exchange Server,
        they might not be available in your local Outlook data file. If that's
        the case, see the{' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Exchange-Server">
          Exchange Server
        </Link>{' '}
        instructions.
        <br />
        <br />
        Here's how to add local Microsoft Outlook task data sources to
        PrintableCal:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the{' '}
          <strong>Local -&gt; Outlook Tasks (Local) </strong>option. The{' '}
          <strong>Add Calendar Source - Outlook Tasks (Local) </strong>window
          will appear.
          <br />
          <Image alt="" src={outlook_local_tasks} style={{ width: 590 }} />
        </li>
        <li>
          Local task folders will be listed, each which a checkbox to the left
          of its name.
        </li>
        <li>
          Use the checkboxes to select the task folders you'd like to make
          available to PrintableCal.
        </li>
        <li>
          ​Click the <strong>OK </strong>button to add the selected Outlook task
          folders to PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Outlook-Tasks-Local"
      commentsId="commentsplus_post_1200_489"
      title="Print Outlook Tasks"
      description="PrintableCal can create printable calendars containing tasks defined in Microsoft Outlook."
      keywords="print Outlook tasks, customize Outlook printing, calendar template, word-wrap Outlook tasks, import Outlook tasks, Excel calendar, Word calendar, printable calendar"
      content={content}
    />
  );
};

export default Documentation;
